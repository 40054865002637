body {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
  font-family: "Osaka";
}

.App {
  text-align: center;
}

/* ======================================
    Header
=======================================*/
.header-wrapper {
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  text-align: left;
  color: rgb(23, 23, 23);
}
.logo-bland {
  margin: 20px 0 0 30px;
  width: 111px;
  height: 50px;
  background-image: url("./images/Logo-kuro.png");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.header-menu {
  position: fixed;
  top: 0;
  right: 40px;
}
.header-menu-list {
  display: flex;
  list-style: none;
  margin-right: 40px;
  cursor: pointer;
  top: 0;
  right: 40px;
}
.header-menu-list li {
  text-decoration: none;
  margin-left: 40px;
}
.header-menu-list li p {
  padding: 8px;
  font-weight: bold;
  height: 20px;
  /* background-color: #f6f6f6ad; */
}
.header-menu-list li a {
  text-decoration: none;
  color: rgb(23, 23, 23);
}
.header-menu-list li:hover {
  opacity: 0.5;
  transition-duration: 0.5s;
}
@media screen and (max-width: 520px) {
  .header-menu {
    right: 0;
    font-size: 14px;
  }
  .header-menu-about {
    display: none;
  }
  .header-menu-service {
    display: none;
  }
  .logo-bland {
    margin: 20px 0 0 20px;
    width: 90px;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
  }
}

/* ======================================
    Header END
=======================================*/

/* ======================================
    Wrapper
=======================================*/

.wrapper {
  margin-top: 80px;
  width: 100%;
  height: 500px;
  background-image: url("./images/wrapper.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.wrapper-text {
  text-align: right;
  font-size: 48px;
  font-weight: bold;
  padding-top: 250px;
  color: #fff;
  animation-name: amim-text;
  animation-duration: 2s;
  animation-iteration-count: 1;
}
@keyframes amim-text {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  51% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.w-txt-1 {
  margin-right: 120px;
  margin-bottom: -30px;
}
.w-txt-2 {
  margin-right: 40px;
}
@media screen and (max-width: 520px) {
  .wrapper {
    width: 100%;
    height: 300px;
  }
  .wrapper-text {
    font-size: 28px;
    padding-top: 200px;
  }
  .w-txt-1 {
    margin-right: 10px;
    margin-bottom: -20px;
  }
  .w-txt-2 {
    margin-right: 10px;
  }
  .wrapper {
    height: 420px;
  }
}
/* ======================================
    Service
=======================================*/
.service-wrapper {
  width: 100%;
  height: 800px;
  padding-top: 80px;
}

.title1 {
  font-size: 48px;
  font-family: "Helvetica";
  font-weight: bold;
  color: rgb(23, 23, 23);
  margin-bottom: -16px;
  letter-spacing: 1px;
}
.ser-cnt1 {
  margin-top: 60px;
}
.ser-cnt1-subtitle {
  font-size: 32px;
}
.ser-cnt1-txt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.ser-cnt2 {
  margin: 40px auto;
  width: 90%;
  height: 500px;
}
table tr {
  width: 50%;
  height: 50%;
}
.ser-cnt {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.ser-cnt li {
  width: 50%;
  height: 250px;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
  white-space: pre-wrap;
}
.ser-menu {
  background-color: rgb(81, 81, 81, 0.6);
  padding: 8px;
  /* position: absolute; */
  height: 20px;
  margin: 10px auto;
  line-height: 250px;
}

.ser-cnt-image1 {
  /* background-color: azure; */
  background-image: url("./images/image1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.ser-cnt-image2 {
  /* background-color: rgb(114, 177, 249); */
  background-image: url("./images/image2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.ser-cnt-image3 {
  /* background-color: rgb(249, 200, 87); */
  background-image: url("./images/image3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.ser-cnt-image4 {
  /* background-color: rgb(249, 114, 161); */
  background-image: url("./images/image4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.ser-cnt-hover {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
}
.ser-cnt-hover li {
  width: 50%;
  height: 250px;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  white-space: pre-wrap;
}

@media screen and (max-width: 520px) {
  .title1 {
    font-size: 40px;
  }
  .service-wrapper {
    height: 800px;
    padding-top: 40px;
  }
  .ser-cnt1-subtitle {
    font-size: 20px;
  }
  .ser-cnt1 {
    margin-top: 30px;
  }
  .ser-cnt1-txt {
    font-size: 12px;
  }
  .ser-cnt2 {
    margin: 40px auto;
    width: 100%;
    height: 500px;
  }
  .ser-cnt li {
    font-size: 16px;
  }
}
/* ======================================
    Service END
=======================================*/

/* ======================================
    Contact
=======================================*/
.contact-wrapper {
  width: 100%;
  padding-top: 80px;
}
.contact-txt {
  margin: 80px 0;
  font-size: 14px;
  letter-spacing: 2px;
}
.form-btn {
  width: 188px;
  height: 50px;
  background-color: #479fe9;
  color: #fff;
  line-height: 50px;
  margin: 24px auto;
  font-weight: bold;
  cursor: pointer;
}
.form-btn:hover {
  background-color: #fff;
  color: #479fe9;
  transition-duration: 0.5s;
}
.contant-cover {
  width: 100%;
  height: 350px;
  background-image: url("./images/image5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.form-cnt {
  text-decoration: none;
}
@media screen and (max-width: 520px) {
  .contact-wrapper {
    padding-top: 20px;
  }
}
/* ======================================
    Contact END
=======================================*/
/* ======================================
    About
=======================================*/
.about-wrapper {
  width: 100%;
  height: 950px;
  padding-top: 60px;
}
.about-table {
  width: 60%;
  margin: 60px auto;
  /* border: 1px solid gray; */
  text-align: left;
}
.about-table td {
  padding-left: 40px;
  padding-bottom: 16px;
  vertical-align: top;
}
.google-map {
  width: 500px;
  height: 300px;
  margin: 0 auto;
}
.map-iframe-desktop {
  border: none;
}
.map-iframe-mobile {
  border: none;
  opacity: 0;
}

@media screen and (max-width: 520px) {
  .about-wrapper {
    padding-top: 30px;
    height: 750px;
  }
  .about-table {
    width: 90%;
    font-size: 11px;
  }
  .about-table td {
    padding-left: 18px;
    padding-bottom: 10px;
  }
  .map-iframe-desktop {
    display: none;
  }
  .google-map {
    width: 300px;
    height: 180px;
  }
  .map-iframe-mobile {
    opacity: 1;
  }
}
/* ======================================
    About END
=======================================*/
/* ======================================
    Footer
=======================================*/
.footer-wrapper {
  width: 100%;
  height: 100px;
  background-color: rgb(59, 59, 59);
  position: relative;
}
.f-logo {
  position: absolute;
  margin: 20px 0 0 60px;
  width: 82px;
  height: 37px;
  background-image: url("./images/Logo-shiro.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.policy-link {
  color: #fff;
  padding: 16px 40px 0 0;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
}
.copyright {
  color: #fff;
  font-size: 12px;
  margin-top: 8px;
}
.policy-link {
  text-decoration: none;
}
@media screen and (max-width: 520px) {
  .footer-wrapper {
    height: 80px;
  }
  .policy-link {
    font-size: 12px;
  }
  .copyright {
    font-size: 10px;
    margin-top: 0;
  }
}
/* ======================================
    Footer END
=======================================*/
/* ======================================
    Form
=======================================*/
.form-wrapper {
  width: 100%;
  height: 700px;
  margin-top: 100px;
  padding-top: 60px;
}
.form-title {
  font-size: 32px;
  font-weight: bold;
}
.contact-form {
  width: 500px;
  margin: 40px auto;
  text-align: left;
}
.input-text {
  width: 500px;
  height: 35px;
  border: none;
  background-color: #fff;
  padding-left: 8px;
  margin: 8px 0 24px 0;
}
.input-textarea {
  width: 500px;
  height: 200px;
  border: none;
  background-color: #fff;
  padding-left: 8px;
  margin-top: 8px;
}
.contact-btn {
  width: 200px;
  height: 40px;
  color: #fff;
  background-color: #479fe9;
  line-height: 40px;
  border: none;
  font-weight: bold;
  margin-top: 24px;
  cursor: pointer;
}
.contact-btn:hover {
  background-color: #0983e8;
}
.alert-success {
  color: rgb(4, 168, 26);
  font-weight: bold;
  font-size: 16px;
}
.con-txt {
  font-size: 13px;
  color: rgb(23, 23, 23);
}

@media screen and (max-width: 520px) {
  .form-wrapper {
    width: 350px;
    margin: 50px auto 0;
  }
  .form-title {
    font-size: 24px;
  }
  .input-text {
    width: 350px;
    height: 35px;
    border: none;
    background-color: #fff;
    padding-left: 8px;
    margin: 8px 0 24px 0;
  }
  .input-textarea {
    width: 350px;
    height: 200px;
  }
  .con-txt {
    font-size: 11px;
  }
  .contact-btn {
    width: 140px;
    height: 40px;
    font-weight: bold;
    margin-top: 24px;
  }
}

.policy-link-f {
  font-weight: bold;
  cursor: pointer;
  color: rgb(23, 23, 23);
}
.policy-link-f a {
  color: rgb(23, 23, 23);
}

/* ======================================
    Form END
=======================================*/

/* ======================================
    Policy
=======================================*/
.policy-wrapper {
  width: 100%;
  margin-top: 200px;
}
.policy-subtitle {
  letter-spacing: 1px;
}
.policy-text {
  padding: 30px 60px;
  text-align: left;
}
@media screen and (max-width: 520px) {
  .f-logo {
    position: absolute;
    margin: 20px 0 0 20px;
    width: 60px;
    height: 27px;
  }
  .policy-wrapper {
    width: 100%;
    margin-top: 150px;
  }
  .title2 {
    font-size: 36px;
  }
  .policy-text {
    font-size: 14px;
  }
}
/* ======================================
    Policy END
=======================================*/
